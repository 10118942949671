<template>
  <v-app>
    <v-container
      id="agendavac"
      class="justify-center mb-6"
    >
      <v-row class="py-6 px-2 text-center justify-center">
        <v-col
          cols="4"
          sm="3"
          md="2"
          lg="2"
        >
          <v-img
            src="@/assets/pmmg-logo.png"
            class="img-header"
          />
        </v-col>
        <v-col
          cols="8"
          sm="9"
          md="10"
          lg="10"
        >
          <div class="py-5 text-header text-left">
            <p class="font-weight-black">
              Biblioteca João XXIII
            </p>
            <p>
              Secretaria de Cultura
            </p>
          </div>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col>
          <base-material-card
            color="#3F51B5"
            class="justify-center text-center"
          >
            <template v-slot:heading>
              {{ updatedAt }}
              <div
                v-if="total > 0"
                style="float: right"
              >
                Total de Livros: {{ total }}
              </div>
            </template>
            <div class="container">
              <v-text-field
                v-if="total > 0"
                v-model="search"
                label="Busca"
                outlined
              />
              <span v-if="countSearch > 0">
                Resultado da busca: {{ countSearch }} {{ (countSearch > 1 && 'livros encontrados') || 'livro encontrado' }}
              </span>
              <span v-if="total === 0">
                <v-progress-linear
                  indeterminate
                  color="indigo"
                />
              </span>
              <v-data-table
                :headers="headers"
                :items="load"
                sort-by="['title', 'author', 'subject']"
                :items-per-page="25"
                item-key="id"
                loading="true"
                loading-text="Carregando livros... Aguarde por favor..."
                no-data-text="Nenhum livro cadastrado."
                no-results-text="Nenhum livro encontrado."
                :header-props="{
                  'sort-by-text': 'Ordenar por'
                }"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-arrow-collapse-left',
                  lastIcon: 'mdi-arrow-collapse-right',
                  prevIcon: 'mdi-minus',
                  nextIcon: 'mdi-plus',
                  'items-per-page-text': 'Livros por página',
                  'page-text': '',
                  'items-per-page-all-text': '25',
                  'items-per-page-options': [25, 50, 75, 100],
                }"
              >
                <template
                  v-slot:item.title="{ item }"
                >
                  <b class="text-h4">
                    {{ item.title }}
                  </b>
                </template>
                <template v-slot:top>
                  <v-dialog
                    v-model="detail"
                    max-width="500px"
                  >
                    <v-card>
                      <v-card-title>
                        <span
                          class="text-h3"
                          style="word-break: break-word"
                        >
                          {{ detailItens.title }}
                        </span>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="4">
                            <v-img
                              :src="detailItens.image"
                              max-width="123px"
                            />
                            <div class="text-caption">
                              Imagem meramente ilustrativa
                              <hr>
                              <b>ISBN:</b> {{ detailItens.isbn }}<br>
                              <!-- <b>ID:</b> {{ detailItens.id }} -->
                            </div>
                          </v-col>
                          <v-col
                            cols="8"
                          >
                            <b>Detalhes:</b><br>{{ detailItens.description }}
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="text-caption justify-center text-center">
                        Informações coletadas automaticamente.<br>Não nos responsabilizamos por possíveis divergências.
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    class="mr-2"
                    @click="loadModal(item)"
                  >
                    mdi-magnify
                  </v-icon>
                </template>
              </v-data-table>
            </div>
          </base-material-card>
        </v-col>
      </v-row>
    </v-container>
    <v-footer
      padless
    >
      <v-row
        justify="center"
        no-gutters
      >
        <v-btn
          v-for="link in links"
          :key="link"
          color="cyan"
          text
          rounded
          class="my-2"
          @click="goLink(link)"
        >
          <v-icon
            color="cyan"
          >
            mdi-{{ link.toLowerCase() }}
          </v-icon>
          {{ link }}
        </v-btn>
        <v-btn
          text
          rounded
          color="cyan"
          class="my-2"
          @click="toggleCookies"
        >
          <v-icon
            color="cyan"
          >
            {{ showCookies ? 'mdi-eye' : 'mdi-eye-off' }}
          </v-icon>
          Cookies
        </v-btn>
        <v-col
          class="lighten-2 text-center"
          cols="12"
        >
          <v-card
            v-if="showCookies"
            id="cookie"
            class="ma-0 pa-5"
            outlined
          >
            Usamos cookies para tornar sua experiencia a mais agradável possível.<br>Se continuar navegando neste site, você assume concordar com nossa<br>
            <span
              style="cursor: pointer"
              @click="goLink('Privacidade')"
            >
              POLÍTICA DE COOKIES E REGISTRO DE NAVEGAÇÃO
            </span>
            <br><v-btn
              class="mr-0 mt-2"
              small
              @click="toggleCookies"
            >
              OK
            </v-btn>
          </v-card>
          <b>PREFEITURA MUNICIPAL DE MOGI GUAÇU</b><br>SECRETARIA DE TECNOLOGIA E INFORMAÇÃO<br>DEPARTAMENTO DE TECNOLOGIA
          <br>
          PMMG/STI/DT BIBLIOTECA 1.0.5 / {{ new Date().getFullYear() }}
          <br>
          <img
            :src="'./uploads/nn.gif'"
            alt="DEV TEAM PMMG"
          >
        </v-col>
      </v-row>
      <lgpd
        v-model="dialog"
        @fecharAlerta="dialog = false"
      />
    </v-footer>
  </v-app>
</template>

<script>
  export default {
    components: {
      Lgpd: () => import('./modules/Lgpd'),
    },
    data: () => ({
      alert: false,
      dialog: false,
      detail: false,
      detailItens: {},
      dialogContent: {},
      links: [
        'Home',
        'Facebook',
        'Instagram',
        'Youtube',
        'Privacidade',
      ],
      messages: {},
      showCookies: true,
      tab: null,
      headers: [
        { text: 'Título', value: 'title', width: '50%' },
        { text: 'Autor', value: 'author', width: '25%' },
        { text: 'Assunto', value: 'subject', width: '20%' },
        { text: 'Mais Informações', value: 'actions', width: '5%', sortable: false, align: 'center', filterable: false },
      ],
      load: [],
      search: '',
      countSearch: 0,
      table: [],
      total: 0,
      updatedAt: '',
    }),
    watch: {
      detail (val) {
        val || this.close()
      },
      search (val) {
        this.tableSearch(val)
      },
    },
    async mounted () {
      this.readDataBase()
      this.showCookies = (!localStorage.getItem('cookies') && localStorage.getItem('cookies') !== false) || false
      this.updatedAt = await this.getUpdated()
    },
    methods: {
      tableSearch (val) {
        val = val !== '' ? val.toLowerCase() : val
        this.load = val !== '' ? this.table.filter(v => (v.title && v.title.toLowerCase().includes(val)) || (v.author && v.author.toLowerCase().includes(val)) || (v.subject && v.subject.toLowerCase().includes(val))) : this.table
        this.countSearch = val !== '' ? this.load.length : 0
      },
      goLink (e) {
        if (e === 'Privacidade') {
          this.dialog = true
        }
        if (e === 'Facebook') {
          window.open('https://www.facebook.com/PrefeituradeMogiGuacu', '_blank')
        }
        if (e === 'Instagram') {
          window.open('https://www.instagram.com/prefeituramogiguacu/', '_blank')
        }
        if (e === 'Youtube') {
          window.open('https://www.youtube.com/channel/UCAMlQ4bD01ZEuYzODJI56Rg', '_blank')
        }
        if (e === 'Home') {
          window.open('https://mogiguacu.sp.gov.br', '_blank')
        }
      },
      toggleCookies () {
        localStorage.setItem('cookies', !localStorage.getItem('cookies'))
        this.showCookies = !this.showCookies
      },
      reset: () => location.reload(),
      close () {
        this.detail = false
      },
      async readDataBase () {
        var table = await (await fetch('datatables.php')).json()
        this.total = table.recordsTotal
        this.table = table.data.map(x => { return { title: x[0], author: x[1], isbn: x[2], subject: x[3], id: x[4], image: 'uploads/default-book.png', description: '' } })
        this.load = this.table
      },
      async getUpdated () {
        return await (await fetch('datefile.php')).text()
      },
      async saveIsbnQuery (bookId, isbn, status, response) {
        var req = {
          book_id: bookId,
          isbn: isbn,
          status: status,
          response: response,
        }
        await fetch('isbn_query.php', {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(req),
        })
      },
      async loadModal (book) {
        this.detailItens = Object.assign({}, book)
        if (!book.isbn) {
          this.saveIsbnQuery(book.id, book.isbn, 'isbn not registered', '')
          this.detailItens.description = 'Nenhuma descrição encontrada com esse ISBN'
          this.detail = true
          return
        }
        let status = ''
        this.searchByISBN(book.isbn).then(data => {
          if (data.totalItems > 0) {
            status = 'isbn found'
            this.detailItens.description = data.items[0].volumeInfo.description
            if ('imageLinks' in data.items[0].volumeInfo) {
              if ('thumbnail' in data.items[0].volumeInfo.imageLinks) {
                this.detailItens.image = data.items[0].volumeInfo.imageLinks.thumbnail
              }
            }
          } else {
            status = 'isbn not found'
            this.detailItens.description = 'Nenhuma descrição encontrada com esse ISBN'
          }
          this.saveIsbnQuery(book.id, book.isbn, status, data)
          this.detail = true
        })
      },
      async searchByISBN (isbn) {
        if (!isbn) { return false }
        isbn = isbn.replace(/[^0-9X]/gi, '')
        var obj = await fetch(`https://www.googleapis.com/books/v1/volumes?q=isbn:${isbn}`)
        return await obj.json()
      },
    },
  }
</script>

<style>
  input[type=text]{
    text-transform: uppercase !important
  }
  .v-radio > .v-label {
    color: black !important
  }
  .v-item--active {
    font-weight: 700
  }
  .text-header p:first-of-type {
    color: #3949AB;
    clear: both;
    font-size: 5vmin;
    margin-bottom:-15px !important
  }
  .text-header p:last-of-type {
    font-size: 3.05vmin;
    color: #9FA8DA;
    clear: both
  }
  .img-header {
    float: left
  }
  body, .v-application { min-width: 360px !important }
  #agendavac {
    max-width: 1200px !important;
    min-width: 340px !important
  }
  .v-card__text > h1 {
    line-height: 1em
  }
  .v-input--selection-controls .v-input__slot > .v-label {
    color: black !important
  }
  .check {
    opacity: 1 !important
  }
  .confirma {
    background-color:#3F51B5
  }
  .v-btn {
     max-width: 100% !important
  }
  @media screen and (min-width: 640px) {
    .container {
      max-width: 100%;
    }
    .text-header p {
      font-size: 8vmin;
      margin-left: -15px;
      float: left
    }
    .v-application .py-5 { float: left }
    .v-application .py-5, .v-application .py-6 {
      padding-top: 0px !important;
      padding-bottom: 0px !important
    }
    .v-application p:first-of-type {
      font-size: 5vmin;
      margin-bottom: 0px
    }
    .text-header p:last-of-type {
      font-size: 3.05vmin;
      padding-top: 0px
    }
  }
  @media screen and (max-width: 639px) {
    .v-radio {
      align-content: right
    }
    .v-application .py-5 { float: left }
    .v-application .py-5, .v-application .py-6 {
      padding-top: 0px !important;
      padding-bottom: 0px !important
    }
    .v-application p:first-of-type {
      margin-bottom: 0px
    }
    .text-header p {
      font-size: 4.3vw;
      margin-left: -10px;
      float: left
    }
    .text-header p:last-of-type {
      color: #9FA8DA;
      clear: both;
      padding-top: 7px
    }
  }
  @media screen
  and (max-height: 639px)
  and (orientation: landscape) {
    .text-header p:first-of-type {
      font-size: 8vmin !important;
      margin-bottom:-15px !important
    }
    .text-header p:last-of-type {
      font-size: 4.85vmin !important;
      padding-top: 5px !important
    }
  }
  .select-buttons > button {
    background-color: #fff !important;
    color: #000 !important;
    font-size: 14px !important
  }
  .select-buttons > button:hover,
  .select-buttons > .v-btn--active {
    background-color: #518eb1 !important;
    color: white !important
  }
  .select-buttons > button:hover { opacity: .8 }
  @media screen and (max-width:640px) {
    .v-btn-toggle {
      border-radius: 0 !important;
      display: block !important;
      max-width: 100% !important
    }
    .select-buttons > button {
      border: 1px solid rgba(0, 0, 0, .12) !important
    }
  }
  .theme--light.v-btn.v-btn--disabled {
    color: #777 !important;
  }
  .disabled { background-color: #e2e2e2 !important }
  .container { padding-bottom: 0 }
  #cookie {
    max-width: 600px;
    position: fixed;
    bottom: 10px;
    display: grid;
    left: 10px;
    background: rgba(0,0,0,.8);
    color: #fff;
    z-index: 99999999
  }
  .theme--light.v-footer {
    background-color: #fff !important
  }
 .theme--light.v-footer img {
    opacity: .1
  }
  .theme--light.v-footer img:hover {
    opacity: .5
  }
  .select-buttons > button {
    color: #777777 !important;
    font-size: 18px !important;
  }
  .select-buttons > button svg {
    fill: #777777
  }
  .select-buttons > button:hover svg, .select-buttons > button.v-item--active svg {
    fill: #FFFFFF
  }
  #btn_idade {
    background-color: #A5D6A7 !important
  }
  #btn_idade.v-item--active {
    background-color: #2E7D32 !important;
    color: #FFFFFF !important;
    opacity: 1
  }
  #btn_idade.v-item--active.v-btn--disabled {
    color: #FFFFFF !important
  }
  #btn_profissao {
    background-color: #EF9A9A !important
  }
  #btn_profissao.v-item--active {
    background-color: #F44336 !important;
    opacity: 1
  }
  #btn_comorbidade {
    background-color: #90CAF9 !important
  }
  #btn_comorbidade.v-item--active {
    background-color: #1565C0 !important;
    opacity: 1
  }
  .v-btn__content {
    display: block !important
  }
  .v-btn-toggle:not(.v-btn-toggle--dense) .v-btn.v-btn.v-size--default {
    height: auto !important
  }
  .row-pointer {
    cursor: pointer
  }
  </style>
