var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-container',{staticClass:"justify-center mb-6",attrs:{"id":"agendavac"}},[_c('v-row',{staticClass:"py-6 px-2 text-center justify-center"},[_c('v-col',{attrs:{"cols":"4","sm":"3","md":"2","lg":"2"}},[_c('v-img',{staticClass:"img-header",attrs:{"src":require("@/assets/pmmg-logo.png")}})],1),_c('v-col',{attrs:{"cols":"8","sm":"9","md":"10","lg":"10"}},[_c('div',{staticClass:"py-5 text-header text-left"},[_c('p',{staticClass:"font-weight-black"},[_vm._v(" Biblioteca João XXIII ")]),_c('p',[_vm._v(" Secretaria de Cultura ")])])])],1),_c('v-row',{staticClass:"justify-center"},[_c('v-col',[_c('base-material-card',{staticClass:"justify-center text-center",attrs:{"color":"#3F51B5"},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v(" "+_vm._s(_vm.updatedAt)+" "),(_vm.total > 0)?_c('div',{staticStyle:{"float":"right"}},[_vm._v(" Total de Livros: "+_vm._s(_vm.total)+" ")]):_vm._e()]},proxy:true}])},[_c('div',{staticClass:"container"},[(_vm.total > 0)?_c('v-text-field',{attrs:{"label":"Busca","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}):_vm._e(),(_vm.countSearch > 0)?_c('span',[_vm._v(" Resultado da busca: "+_vm._s(_vm.countSearch)+" "+_vm._s((_vm.countSearch > 1 && 'livros encontrados') || 'livro encontrado')+" ")]):_vm._e(),(_vm.total === 0)?_c('span',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"indigo"}})],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.load,"sort-by":"['title', 'author', 'subject']","items-per-page":25,"item-key":"id","loading":"true","loading-text":"Carregando livros... Aguarde por favor...","no-data-text":"Nenhum livro cadastrado.","no-results-text":"Nenhum livro encontrado.","header-props":{
                'sort-by-text': 'Ordenar por'
              },"footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-arrow-collapse-left',
                lastIcon: 'mdi-arrow-collapse-right',
                prevIcon: 'mdi-minus',
                nextIcon: 'mdi-plus',
                'items-per-page-text': 'Livros por página',
                'page-text': '',
                'items-per-page-all-text': '25',
                'items-per-page-options': [25, 50, 75, 100],
              }},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
              var item = ref.item;
return [_c('b',{staticClass:"text-h4"},[_vm._v(" "+_vm._s(item.title)+" ")])]}},{key:"top",fn:function(){return [_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.detail),callback:function ($$v) {_vm.detail=$$v},expression:"detail"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h3",staticStyle:{"word-break":"break-word"}},[_vm._v(" "+_vm._s(_vm.detailItens.title)+" ")])]),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-img',{attrs:{"src":_vm.detailItens.image,"max-width":"123px"}}),_c('div',{staticClass:"text-caption"},[_vm._v(" Imagem meramente ilustrativa "),_c('hr'),_c('b',[_vm._v("ISBN:")]),_vm._v(" "+_vm._s(_vm.detailItens.isbn)),_c('br')])],1),_c('v-col',{attrs:{"cols":"8"}},[_c('b',[_vm._v("Detalhes:")]),_c('br'),_vm._v(_vm._s(_vm.detailItens.description)+" ")])],1)],1),_c('v-card-actions',{staticClass:"text-caption justify-center text-center"},[_vm._v(" Informações coletadas automaticamente."),_c('br'),_vm._v("Não nos responsabilizamos por possíveis divergências. ")])],1)],1)]},proxy:true},{key:"item.actions",fn:function(ref){
              var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.loadModal(item)}}},[_vm._v(" mdi-magnify ")])]}}])})],1)])],1)],1)],1),_c('v-footer',{attrs:{"padless":""}},[_c('v-row',{attrs:{"justify":"center","no-gutters":""}},[_vm._l((_vm.links),function(link){return _c('v-btn',{key:link,staticClass:"my-2",attrs:{"color":"cyan","text":"","rounded":""},on:{"click":function($event){return _vm.goLink(link)}}},[_c('v-icon',{attrs:{"color":"cyan"}},[_vm._v(" mdi-"+_vm._s(link.toLowerCase())+" ")]),_vm._v(" "+_vm._s(link)+" ")],1)}),_c('v-btn',{staticClass:"my-2",attrs:{"text":"","rounded":"","color":"cyan"},on:{"click":_vm.toggleCookies}},[_c('v-icon',{attrs:{"color":"cyan"}},[_vm._v(" "+_vm._s(_vm.showCookies ? 'mdi-eye' : 'mdi-eye-off')+" ")]),_vm._v(" Cookies ")],1),_c('v-col',{staticClass:"lighten-2 text-center",attrs:{"cols":"12"}},[(_vm.showCookies)?_c('v-card',{staticClass:"ma-0 pa-5",attrs:{"id":"cookie","outlined":""}},[_vm._v(" Usamos cookies para tornar sua experiencia a mais agradável possível."),_c('br'),_vm._v("Se continuar navegando neste site, você assume concordar com nossa"),_c('br'),_c('span',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.goLink('Privacidade')}}},[_vm._v(" POLÍTICA DE COOKIES E REGISTRO DE NAVEGAÇÃO ")]),_c('br'),_c('v-btn',{staticClass:"mr-0 mt-2",attrs:{"small":""},on:{"click":_vm.toggleCookies}},[_vm._v(" OK ")])],1):_vm._e(),_c('b',[_vm._v("PREFEITURA MUNICIPAL DE MOGI GUAÇU")]),_c('br'),_vm._v("SECRETARIA DE TECNOLOGIA E INFORMAÇÃO"),_c('br'),_vm._v("DEPARTAMENTO DE TECNOLOGIA "),_c('br'),_vm._v(" PMMG/STI/DT BIBLIOTECA 1.0.5 / "+_vm._s(new Date().getFullYear())+" "),_c('br'),_c('img',{attrs:{"src":'./uploads/nn.gif',"alt":"DEV TEAM PMMG"}})],1)],2),_c('lgpd',{on:{"fecharAlerta":function($event){_vm.dialog = false}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }